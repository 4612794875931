/**
 * 需要传入文件获取对象
 * {"name":"111","url":"1.png"}
 */
export const getImgUrl = function (fileItem) {
  let url = fileItem
  if (fileItem instanceof Object) {
    url = fileItem.url
  }
  if (url.indexOf('http') < 0) {
    url = 'https://image.hzzyhw.com/' + url
  }
  return url
}
