/*
 * @Author: your name
 * @Date: 2021-08-13 11:38:51
 * @LastEditTime: 2022-01-18 12:36:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";
import store from "store";
import storeKeys from "./storeKeys.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // api: "http://localhost:6004/admin/",
    api: "https://api.photoyun.com/admin/",
    imgPrefix: "https://image.photoyun.com/",
    user: store.get(storeKeys.USER) || {},
    menus: store.get(storeKeys.MENUS) || []
  },
  mutations: {
    saveUser(state, user) {
      state.user = user;
      store.set(storeKeys.USER, user);
    },
    saveMenus(state, menus) {
      state.menus = menus;
      store.set(storeKeys.MENUS, menus);
    }
  },
  actions: {},
  modules: {}
});
