/*
 * @Author: your name
 * @Date: 2021-08-13 11:38:51
 * @LastEditTime: 2021-08-13 14:30:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\store\storeKeys.js
 */
export default {
  TOKEN: "HL_TOKEN",
  USER: "HL_USER",
  MENUS: "HL_MENUS"
};
