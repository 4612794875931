/*
 * @Author: your name
 * @Date: 2021-08-13 11:37:26
 * @LastEditTime: 2021-09-28 10:25:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";

const Login = () => import("@/views/login/views/Login.vue");
const Index = () => import("@/views/common/views/Index.vue");
const User = () => import("@/views/user/views/list.vue");
const Member = () => import("@/views/member/views/list.vue");
const Banner = () => import("@/views/banner/views/list.vue");
const Company = () => import("@/views/company/views/list.vue");
const Record = () => import("@/views/record/views/list.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/Login",
    name: "Login",
    component: Login
  },
  {
    path: "/",
    component: Index,
    redirect: "/Banner",
    children: [
      {
        path: "/User",
        name: "User",
        component: User
      },
      {
        path: "/Member",
        name: "Member",
        component: Member
      },
      {
        path: "/Banner",
        name: "Banner",
        component: Banner
      },
      {
        path: "/Company",
        name: "Company",
        component: Company
      },
      {
        path: "/Record",
        name: "Record",
        component: Record
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  routes
});

export default router;
