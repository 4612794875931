/*
 * @Author: your name
 * @Date: 2021-08-13 11:17:23
 * @LastEditTime: 2021-09-26 09:59:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import "./theme/index.css";
import ElementUI from "element-ui";
import router from "./router";
import store from "./store";
import storejs from "store";
import dayjs from "dayjs";
import storeKeys from "./store/storeKeys.js";
import { getImgUrl } from "./utils/ImageUtil.js";

Vue.prototype.$dayjs = dayjs;
Vue.prototype.$store = store;

Vue.filter("imgUrl", function(url) {
  return getImgUrl(url);
});

Vue.config.productionTip = false;
Vue.use(ElementUI);

// 登录控制
router.beforeEach((to, from, next) => {
  // 如果未匹配到路由, 如果上级也未匹配到路由则跳转首页，如果上级能匹配到则转上级路由
  if (to.matched.length === 0) {
    from.name ? next({ name: from.name }) : next("/Login");
    return;
  }
  const black = ["/Login"];
  const user = storejs.get(storeKeys.USER);
  if (black.indexOf(to.path) !== -1) {
    return next();
  } else {
    if (user) {
      return next();
    } else {
      return next({ path: "/Login" });
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");